<template>
    <router-view></router-view>
</template>

<script>
import { defineComponent, defineAsyncComponent, computed, onBeforeMount, } from "vue";

import { useRouter, useRoute, } from "vue-router";
import { useUserStore } from "@/stores/user.js";

export default defineComponent({
    components: {

    },
    setup () {
        const router = useRouter();
        const route = useRoute();

        const userStore = useUserStore();

        const userStatus = computed(() => userStore.status);

        onBeforeMount(
            async () => {
                try {
                    await userStore.loadToken();
                    if (userStatus.value === "loggedIn") {
                        const target = {
                            "path": route.query.to ? route.query.to : "/",
                            "query": Object.assign({}, route.query, { "to": undefined }),
                        };
                        await router.push(target);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        );

        return {

        };
    }
});
</script>

<style lang="scss" scoped>

</style>
