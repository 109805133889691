import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faLocationArrow as faLocationArrowSolid,
    faXmark as faXmarkSolid,
    faWallet as faWalletSolid,
    faRightFromBracket as faRightFromBracketSolid,
    faUserPlus as faUserPlusSolid,
    faRightToBracket as faRightToBracketSolid,
    faUser as faUserSolid,
    faFloppyDisk as faFloppyDiskSolid,
    faStar as faStarSolid,
    faStars as faStarsSolid,
    faArrowRight as faArrowRightSolid,
    faThumbtack as faThumbtackSolid,
    faLocationDot as faLocationDotSolid,
    faLocationCheck as faLocationCheckSolid,
    faMapLocationDot as faMapLocationDotSolid,
    faMegaphone as faMegaphoneSolid,
    faFileSignature as faFileSignatureSolid,
    faEye as faEyeSolid,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faBars as faBarsRegular,
    faCupStrawSwoosh as faCupStrawSwooshRegular,
    faStar as faStarRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {

} from "@fortawesome/pro-light-svg-icons";

import {
    faMagnifyingGlassLocation as faMagnifyingGlassLocationDuotone,
    faMagnifyingGlassPlus as faMagnifyingGlassPlusDuotone,
    faFilterList as faFilterListDuotone,
    faFilterCircleXmark as faFilterCircleXmarkDuotone,
    faSolarSystem as faSolarSystemDuotone,
    faRocketLaunch as faRocketLaunchDuotone,
    faUserPlus as faUserPlusDuotone,
} from "@fortawesome/pro-duotone-svg-icons";

import {

} from "@fortawesome/free-brands-svg-icons";

library.add(
    faLocationArrowSolid,
    faXmarkSolid,
    faWalletSolid,
    faRightFromBracketSolid,
    faUserPlusSolid,
    faRightToBracketSolid,
    faUserSolid,
    faFloppyDiskSolid,
    faStarSolid,
    faStarsSolid,
    faArrowRightSolid,
    faThumbtackSolid,
    faLocationDotSolid,
    faLocationCheckSolid,
    faMapLocationDotSolid,
    faMegaphoneSolid,
    faFileSignatureSolid,
    faEyeSolid,

    faBarsRegular,
    faCupStrawSwooshRegular,
    faStarRegular,

    faMagnifyingGlassLocationDuotone,
    faMagnifyingGlassPlusDuotone,
    faFilterListDuotone,
    faFilterCircleXmarkDuotone,
    faSolarSystemDuotone,
    faRocketLaunchDuotone,
    faUserPlusDuotone,
);
