import hexString from "@/api/user/utilities/hexString.js";
import digestMessage from "@/api/user/utilities/digestMessage.js";

export const controller = new AbortController();
export async function login (countryCode, phone, password, ) {
    const hashed = await digestMessage(password);
    const response = await fetch(
        `${ import.meta.env.VITE_API_URL }/${ import.meta.env.VITE_API_VERSION }/user/login`,
        {
            "method": "POST",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            "signal": controller.signal,
            "body": JSON.stringify({
                countryCode, phone,
                "password": hexString(hashed),
                "mode": "phone"
            }),
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            return incomingToken;
        }
        throw await response.json();
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default login;
