import localforage from "localforage";

export async function logout () {
    const token = await localforage.getItem(import.meta.env.VITE_TOKEN_NAME);
    const response = await fetch(
        `${ import.meta.env.VITE_API_URL }/${ import.meta.env.VITE_API_VERSION }/user/logout`,
        {
            "method": "DELETE",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
        }
    );

    if (response.ok) {
        await localforage.setItem(import.meta.env.VITE_TOKEN_NAME, "");
        return;
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default logout;
