import hexString from "@/api/user/utilities/hexString.js";
import digestMessage from "@/api/user/utilities/digestMessage.js";

export const controller = new AbortController();
export async function register (name, countryCode, phone, password) {
    const hashed = await digestMessage(password);
    const response = await fetch(
        `${ import.meta.env.VITE_API_URL }/${ import.meta.env.VITE_API_VERSION }/user/register`,
        {
            "method": "POST",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            "signal": controller.signal,
            "body": JSON.stringify(
                {
                    name, countryCode, phone,
                    "password": hexString(hashed),
                    "mode": "phone",
                    "twoFactorAuth": false
                }
            ),
        }
    );

    if (response.ok) {
        let user = await response.json();
        if (user) {
            return user;
        }
        throw "RegisterView error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default register;
