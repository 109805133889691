import localForage from "localforage";

export const controller = new AbortController();
export async function updateProfile (profile) {
    const token = await localForage.getItem(import.meta.env.VITE_TOKEN_NAME);
    const response = await fetch(
        `${ import.meta.env.VITE_API_URL }/${ import.meta.env.VITE_API_VERSION }/user`,
        {
            "method": "PATCH",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            "signal": controller.signal,
            "body": JSON.stringify({ profile, }),
        }
    );

    if (response.ok) {
        const user = await response.json();
        if (user) {
            return user;
        }
        throw "USER PATCH PROFILE error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default updateProfile;
