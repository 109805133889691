import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/stores/user.js";

const routes = [
    {
        path: "/welcome",
        component: () => import("@/layouts/Welcome.vue"),
        children: [
            {
                path: "",
                component: () => import("@/views/Welcome/LoginView.vue"),
                beforeEnter (to, from, next) {
                    const store = useUserStore();
                    if (store.status === "loggedIn") {
                        const route = {
                            "path": to.query.to ? to.query.to : "/",
                            "query": Object.assign({}, to.query, { "to": undefined }),
                        };
                        next(route);
                        return;
                    }
                    next();
                },
            },
            {
                path: "register",
                component: () => import("@/views/Welcome/RegisterView.vue"),
                beforeEnter (to, from, next) {
                    const store = useUserStore();
                    if (store.status === "loggedIn") {
                        const route = {
                            "path": to.query.to ? to.query.to : "/",
                            "query": Object.assign({}, to.query, { "to": undefined }),
                        };
                        next(route);
                        return;
                    }
                    next();
                },
            },
            {
                path: "stargazer",
                component: () => import("@/views/Welcome/StargazerView.vue"),
                beforeEnter(to, from, next) {
                    const store = useUserStore();
                    if (store.status !== "loggedIn") {
                        const route = {
                            "path": "/welcome/register",
                            "query": {
                                ...to.query,
                                "to": to.path,
                            }
                        };
                        next(route);
                        return;
                    }
                    next();
                },
            },
            {
                path: "stargazer/add",
                component: () => import("@/views/Welcome/AddStarView.vue"),
                beforeEnter(to, from, next) {
                    const store = useUserStore();
                    if (store.status !== "loggedIn") {
                        const route = {
                            "path": "/welcome/register",
                            "query": {
                                ...to.query,
                                "to": to.path,
                            }
                        };
                        next(route);
                        return;
                    }
                    next();
                },
            },
            {
                path: "terms",
                component: () => import("@/views/Default/TermsView.vue"),
            },
            {
                path: "privacy-policy",
                component: () => import("@/views/Default/PrivacyPolicyView.vue"),
            },
        ],
    },
    {
        path: "/",
        component: () => import("@/layouts/Default.vue"),
        beforeEnter(to, from, next) {
            const store = useUserStore();
            if (store.status !== "loggedIn") {
                const route = {
                    "path": "/welcome/register",
                    "query": {
                        ...to.query,
                        "to": to.path,
                    }
                };
                next(route);
                return;
            }
            next();
        },
        children: [
            {
                path: "",
                component: () => import("@/views/Default/HomeView.vue"),
            },
            {
                path: "my-stars",
                component: () => import("@/views/Default/MyStarsView.vue"),
            },
            {
                path: "my-stars/add",
                component: () => import("@/views/Default/AddStarView.vue"),
            },
            {
                path: "my-points",
                component: () => import("@/views/Default/MyPointsView.vue"),
            },
            {
                path: "terms",
                component: () => import("@/views/Default/TermsView.vue"),
            },
            {
                path: "privacy-policy",
                component: () => import("@/views/Default/PrivacyPolicyView.vue"),
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/welcome/register"
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});

export default router;
