import localForage from "localforage";

export const controller = new AbortController();
export async function authToken (
    tokenToBeTested
) {
    const token = await localForage.getItem(import.meta.env.VITE_TOKEN_NAME) || tokenToBeTested;
    const response = await fetch(
        `${ import.meta.env.VITE_API_URL }/${ import.meta.env.VITE_API_VERSION }/user/authenticate`,
        {
            "method": "GET",
            "headers": {
                "Authorization": `Bearer ${ token }`
            },
            "signal": controller.signal,
        }
    );

    if (response.ok) {
        await localForage.setItem(import.meta.env.VITE_TOKEN_NAME, token);
        const user = await response.json();
        delete user.status;
        return user;
    }

    if (response.status >= 400) {
        await localForage.removeItem(import.meta.env.VITE_TOKEN_NAME);
        throw await response.text();
    }

    throw "Internal server error";
}

export default authToken;
