import { defineStore } from "pinia";

import authToken from "../api/user/authToken.js";
import register from "../api/user/register.js";
import login from "../api/user/login.js";
import logout from "../api/user/logout.js";
import updateProfile from "@/api/user/updateProfile.js";

export const useUserStore = defineStore({
    id: "user",
    state () {
        return {
            status: "loggedOut",
            message: "",
            loggedIn: false,
            loginFailed: false,
            registerFailed: false,
            token: "",
            user: {},
        };
    },
    getters: {
        info (state) {
            return state.user;
        },
        role (state) {
            return state.user?.role ?? "";
        },
    },
    actions: {
        async loadToken (token = "",) {
            this.status = "loggingIn";
            let user;
            try {
                user = await authToken(token);
            } catch (e) {
                console.error(e);
                this.status = "loginFailed"
                this.message = e.reason;
                throw e;
            }
            this.message = "";
            this.user = user;
            this.status = "loggedIn";
            return token;
        },
        async register (name, countryCode, phone, password) {
            this.status = "registering";
            this.message = "";
            try {
                await register(name, countryCode, phone, password);
            } catch (e) {
                console.error(e);
                this.status = "registerFailed";
                this.message = e.reason;
                throw e;
            }
            try {
                this.message = "loggingIn";
                const token = await login(countryCode, phone, password);
                const user = await authToken(token);
                this.user = user;
                this.status = "loggedIn";
                return user;
            } catch (e) {
                console.error(e);
                this.status = "loginFailed";
                this.message = e.reason;
                throw e;
            }
        },
        async login (countryCode, phone, password) {
            this.status = "loggingIn";
            this.message = "";
            try {
                const token = await login(countryCode, phone, password);
                const user = await authToken(token);
                this.user = user;
                this.status = "loggedIn";
                return user;
            } catch (e) {
                console.log(e);
                this.status = "loginFailed";
                this.message = e.reason;
                throw e;
            }
        },
        async logout () {
            try {
                await logout();
                this.message = "";
                this.status = "loggedOut";
            } catch (e) {
                console.error(e);
                this.message = e.reason;
                throw e;
            }
        },
        async updateProfile (profile) {
            const user = await updateProfile(profile);
            this.user = user;
            return user;
        },
    },
});
